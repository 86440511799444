html {
  transition: all ease-in 0.3s;
  font-size: 62.5%;
  scroll-behavior: smooth;
  background: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 10%);
}

html[data-theme=dark] {
  background: hsl(0, 0%, 10%);
  color: hsl(0, 0%, 90%);
}

.shadow {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}
.shadow:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.3);
}

[data-theme=dark] .shadow {
  box-shadow: 0 6px 16px 0 rgba(243, 243, 243, 0.2);
}
[data-theme=dark] .shadow:hover {
  box-shadow: 0 8px 12px 0 rgba(243, 243, 243, 0.3);
}
[data-theme=dark] .contact .contact-content .contact-form div p {
  color: hsl(0, 0%, 90%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

a {
  color: unset;
  text-decoration: none;
}

.icon {
  min-height: 3.2rem;
  min-width: 3.2rem;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: hsl(209, 91%, 57%);
  transform-origin: bottom right;
  transition: transform 0.2s linear;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar {
  margin-bottom: 8rem;
}
.navbar .navbar-content {
  max-width: 65%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 10vh;
}
.navbar .navbar-content h2 {
  font-size: 4.2rem;
  cursor: pointer;
  user-select: none;
  position: relative;
  padding: 1.2rem;
}
.navbar .navbar-content h2:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: hsl(209, 91%, 57%);
  transform-origin: bottom right;
  transition: transform 0.2s linear;
}
.navbar .navbar-content h2:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.navbar .navbar-content .light-switch {
  display: flex;
  align-items: center;
}
.navbar .navbar-content .nav-links {
  display: flex;
  list-style-type: none;
  min-width: 20rem;
  justify-content: space-between;
}
.navbar .navbar-content .nav-links li {
  user-select: none;
  font-size: 2.2rem;
  font-weight: bold;
  padding: 0.8rem;
}

.hero-section {
  margin-bottom: 8rem;
}
.hero-section .hero-section-content {
  max-width: 65%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hero-section .hero-section-content .hero-about h1 {
  font-size: 5.6rem;
  line-height: 5.2rem;
}
.hero-section .hero-section-content .hero-about h2 {
  font-size: 3.8rem;
  margin-bottom: 4rem;
  color: rgb(45, 148, 245);
}
.hero-section .hero-section-content .hero-about p {
  font-size: 2.6rem;
  max-width: 65%;
  font-weight: 400;
}
.hero-section .hero-section-content .hero-yassincodes-project {
  display: flex;
  justify-content: flex-end;
}
.hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-description {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: 16rem;
}
.hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-description h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 8px;
}
.hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-description button {
  width: 9.1rem;
  height: 3.6rem;
  cursor: pointer;
  font-size: 2rem;
  transition: all ease 0.2s;
  border: none;
  border-radius: 4px;
  user-select: none;
  width: 16rem;
  height: 4.8rem;
  font-size: 2.4rem;
  background: hsl(209, 91%, 47%);
  color: hsl(0, 0%, 100%);
}
.hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-description button:hover {
  background: hsl(209, 91%, 57%);
}
.hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-gif {
  display: flex;
  border: 1px solid hsla(0, 0%, 90%, 0.4);
  margin-left: 2rem;
  border-radius: 8px;
}
.hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-gif video {
  max-height: 320px;
  border-radius: 8px;
}

.projects {
  margin-bottom: 8rem;
}
.projects .projects-content {
  display: flex;
  flex-direction: column;
  max-width: 65%;
  margin: 0 auto;
}
.projects .projects-content h2 {
  font-size: 4rem;
  text-align: center;
}
.projects .projects-content .projects-list {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.bubblegum-pink {
  background: linear-gradient(to right bottom, hsl(330, 100%, 74%), hsla(0, 0%, 90%, 0.9));
}

.bubblegum-blue {
  background: linear-gradient(to right bottom, hsl(186, 100%, 69%), hsla(0, 0%, 90%, 0.9));
}

.bubblegum-green {
  background: linear-gradient(to right bottom, hsl(141, 100%, 66%), hsla(0, 0%, 90%, 0.9));
}

.bubblegum-orange {
  background: linear-gradient(to right bottom, hsl(26, 100%, 57%), hsla(0, 0%, 90%, 0.9));
}

.bubblegum-purple {
  background: linear-gradient(to right bottom, hsl(290, 94%, 69%), hsla(0, 0%, 90%, 0.9));
}

.bubblegum-yellow {
  background: linear-gradient(to right bottom, hsl(63, 100%, 69%), hsla(0, 0%, 90%, 0.9));
}

.bubblegum-darkBlue {
  background: linear-gradient(to right bottom, hsl(223, 67%, 55%), hsla(0, 0%, 90%, 0.9));
}

.project {
  min-width: 32rem;
  max-width: 30rem;
  border-radius: 0.8rem;
  margin: 28px 28px;
  color: hsl(0, 0%, 10%);
  flex: 0 0 33.3%;
}
.project .project-content {
  min-height: 50rem;
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.project .project-content h3 {
  font-size: 2.4rem;
  text-align: center;
}
.project .project-content img {
  max-width: 100%;
  border-radius: 4px;
}
.project .project-content p {
  font-size: 1.8rem;
  font-weight: bold;
}
.project .project-content .project-buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.project .project-content .project-buttons button {
  width: 9.1rem;
  height: 3.6rem;
  cursor: pointer;
  font-size: 2rem;
  transition: all ease 0.2s;
  border: none;
  border-radius: 4px;
  user-select: none;
  background: hsl(0, 0%, 10%);
  color: hsl(0, 0%, 90%);
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}
.project .project-content .project-buttons button:hover {
  background: hsl(0, 0%, 20%);
}
.project .project-content .project-buttons button:hover {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.5);
}

.contact {
  margin-bottom: 4rem;
}
.contact h2 {
  text-align: center;
  font-size: 4rem;
  margin-bottom: 2rem;
}
.contact .contact-content {
  max-width: 65%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse;
}
.contact .contact-content .contact-invitation {
  font-size: 2.4rem;
  min-width: 34rem;
  max-width: 34rem;
}
.contact .contact-content .contact-form {
  margin-right: 2rem;
}
.contact .contact-content .contact-form .form-content {
  border: 1px solid hsl(0, 0%, 40%);
  background: hsla(0, 0%, 10%, 0.1);
  padding: 12px;
  min-height: 32rem;
  min-width: 35.2rem;
  max-width: 35.2rem;
  display: flex;
  flex-direction: column;
}
.contact .contact-content .contact-form .form-content p {
  font-size: 1.8rem;
  padding-bottom: 4px;
  color: hsl(0, 0%, 10%);
}
.contact .contact-content .contact-form .form-content .form-name {
  width: 50%;
  height: 3.2rem;
  padding: 6px;
  margin-bottom: 1.4rem;
}
.contact .contact-content .contact-form .form-content .form-email {
  width: 100%;
  height: 3.2rem;
  padding: 6px;
  margin-bottom: 1.4rem;
}
.contact .contact-content .contact-form .form-content textarea {
  width: 100%;
  padding: 8px;
  resize: none;
  margin-bottom: 2rem;
}
.contact .contact-content .contact-form .form-content .form-submit {
  width: 9.1rem;
  height: 3.6rem;
  cursor: pointer;
  font-size: 2rem;
  transition: all ease 0.2s;
  border: none;
  border-radius: 4px;
  user-select: none;
  background: hsl(209, 91%, 47%);
  color: hsl(0, 0%, 100%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.contact .contact-content .contact-form .form-content .form-submit:hover {
  background: hsl(209, 91%, 57%);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.footer {
  background: hsl(0, 0%, 10%);
  border-top: 2px solid hsla(0, 0%, 90%, 0.2);
  color: hsl(0, 0%, 90%);
}
.footer .footer-content {
  max-width: 65%;
  margin: 0 auto;
  padding: 24px 0;
  min-height: 16vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer .footer-content h2 {
  font-size: 3.2rem;
}
.footer .footer-content .footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer .footer-content .footer-links h2 {
  cursor: pointer;
  user-select: none;
  position: relative;
}
.footer .footer-content .footer-links h2:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: hsl(209, 91%, 57%);
  transform-origin: bottom right;
  transition: transform 0.2s linear;
}
.footer .footer-content .footer-links h2:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.footer .footer-content .footer-links .link {
  cursor: pointer;
  padding-bottom: 4px;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  min-width: 7.4rem;
  justify-content: space-between;
  position: relative;
}
.footer .footer-content .footer-links .link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: hsl(209, 91%, 57%);
  transform-origin: bottom right;
  transition: transform 0.2s linear;
}
.footer .footer-content .footer-links .link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.footer .footer-content p {
  text-align: center;
  height: 100%;
  font-size: 1.6rem;
}

@media screen and (max-width: 1112px) {
  .navbar {
    margin-bottom: 4rem;
  }
  .hero-section {
    margin-bottom: 4rem;
  }
  .hero-section .hero-section-content .hero-about h1 {
    font-size: 4.6rem;
  }
  .hero-section .hero-section-content .hero-about h2 {
    font-size: 3.2rem;
  }
  .hero-section .hero-section-content .hero-about p {
    font-size: 2.2rem;
  }
  .hero-section .hero-section-content .hero-yassincodes-project {
    flex-direction: column;
    align-items: flex-end;
  }
  .hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-description {
    flex-direction: row;
    align-items: flex-end;
    justify-content: start;
    margin-bottom: 0.4rem;
    max-width: unset;
  }
  .hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-description h3 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  .hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-description button {
    width: 9.2rem;
    height: 2.8rem;
    font-size: 1.4rem;
  }
  .hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-gif {
    margin-left: 0;
  }
  .hero-section .hero-section-content .hero-yassincodes-project .hero-yassincodes-gif video {
    max-height: 300px;
  }
}
@media screen and (max-width: 1008px) {
  .navbar .navbar-content {
    max-width: 80%;
  }
  .navbar .navbar-content h2 {
    font-size: 3.2rem;
  }
  .navbar .navbar-content .nav-links {
    min-width: 14rem;
  }
  .navbar .navbar-content .nav-links li {
    font-size: 1.8rem;
  }
  .hero-section .hero-section-content {
    max-width: 80%;
  }
  .projects .projects-content {
    align-items: center;
    max-width: 80%;
  }
}
@media screen and (max-width: 860px) {
  .projects .projects-content {
    align-items: center;
    max-width: 80%;
  }
  .projects .projects-content .projects-list {
    flex-direction: column;
    flex-wrap: unset;
  }
  .contact .contact-content {
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }
  .contact .contact-content .contact-invitation {
    width: 90%;
    text-align: center;
  }
  .contact .contact-content .contact-form {
    width: 80%;
  }
  .contact .contact-content .contact-form .form-content {
    min-height: 28rem;
    max-width: unset;
    min-width: unset;
  }
  .footer .footer-content .footer-links h2 {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 624px) {
  .hero-section .hero-section-content .hero-about {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}
@media screen and (max-width: 490px) {
  .navbar .navbar-content {
    justify-content: space-evenly;
  }
  .contact .contact-content .contact-form {
    width: 100%;
  }
  .contact .contact-content .contact-form .form-content {
    min-height: 28rem;
    max-width: unset;
    min-width: unset;
  }
}

